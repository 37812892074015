module.exports = {
  "data":{
    "limit":100,
    "objects":[
      {
        "id":28157862588,
        "name":"Retail",
        "in_nav": false
      },
      {
        "id":28407421064,
        "name":"health & fitness",
        "in_nav": false
      },
      {
        "id":29096368379,
        "name":"healthcare",
        "in_nav": true
      },
      {
        "id":30986900134,
        "name":"Pro Sports",
        "in_nav": false
      },
      {
        "id":33637937006,
        "name":"Hospitality",
        "in_nav": false
      },
      {
        "id":51758763319,
        "name":"Digilock",
        "in_nav": false
      },
      {
        "id":60989813284,
        "name":"manufacturing",
        "in_nav": false
      },
      {
        "id":60992608114,
        "name":"workspace",
        "in_nav": true
      },
      {
        "id":61387586696,
        "name":"company",
        "in_nav": true
      },
      {
        "id":61387586890,
        "name":"design",
        "in_nav": true
      },
      {
        "id":61391655603,
        "name":"products",
        "in_nav": true
      },
      {
        "id":61393215373,
        "name":"sustainability",
        "in_nav": false
      },
      {
        "id":145800716918,
        "name":"education",
        "in_nav": true
      }
    ],
    "offset":0,
    "total":12,
    "totalCount":12
  }
}